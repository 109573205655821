<template>
  <li class="invention-row" :class="rowStyle" :style="{ backgroundColor: inventionBackgroundColor }" @click="$emit('click')">
    <!-- column 1 -->
    <div class="expander">
      <hub-icon v-if="isExpanded" name="chevron-up" size="sm"></hub-icon>
      <hub-icon v-else name="chevron-down" size="sm"></hub-icon>
    </div>
    <!-- column 2 -->
    <div class="references">
      <hub-reference-list
        :invention="{
          id: item.id,
          references: [item.references.find(r => r.startsWith('HARRITY:')) ?? item.references.find(r => r)],
          title: 'Open Invention'
        }"
      />
    </div>
    <!-- column 3 -->
    <div class="milestone-title blue" :class="{ trim: displayMode === 'standard' }" :title="milestoneTitle">
      {{ milestoneTitle }}
    </div>
    <!-- column 4 -->
    <div class="task-title" :title="taskTitle">
      <div class="task-title" :class="{ trim: displayMode === 'standard' }">{{ taskTitle }}</div>
      <div v-if="displayMode === 'standard'" class="awaiting trim">{{ awaiting }}</div>
    </div>
    <!-- column 5-->
    <div class="notes" :class="{ compact: displayMode !== 'standard' }" @click.stop="onEditNotes">
      <div :class="{ trim: displayMode !== 'compact + notes' }">
        <Editor
          v-if="isEditingNotes"
          ref="editor"
          v-model="editedNotes"
          class="hub-editor"
          :floating-panel="false"
          :fixed-height="false"
          @blur="onBlur"
        />

        <styled-content v-if="nextTask?.notes" :class="{ transparent: isEditingNotes }" class="empty" :flat="true" v-html="nextTask?.notes" />
        <span v-else :class="{ transparent: isEditingNotes }" class="notes-placeholder">Click here to add notes...</span>
      </div>
    </div>
    <!-- column 6 -->
    <div class="item-dueAt left">
      <AlertedDueAt :due-at="milestoneClientDate" :color-coding-enabled="true" />
    </div>
    <!-- column 7 -->
    <div class="item-dueAt right">
      <AlertedDueAt :due-at="milestoneDueAt" :color-coding-enabled="true" />
    </div>
    <div></div>
  </li>
</template>
<script>
import { mapState } from 'vuex';
import { differenceInHours, endOfDay } from 'date-fns';
import Icon from '@/components/common/Icon';

import ReferenceList from '@/components/common/ReferenceList.vue';
import AlertedDueAt from './AlertedDueAt.vue';
import StyledContent from '@/components/common/editor/StyledContent';
import Editor from '@/components/common/editor/Editor';

export default {
  components: {
    'hub-icon': Icon,
    'hub-reference-list': ReferenceList,
    AlertedDueAt,
    StyledContent,
    Editor
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    isExpanded: {
      type: Boolean
    },
    empty: {
      type: Boolean
    },
    forUser: {
      type: String,
      default: ''
    }
  },
  emits: ['click', 'onCreateMilestone', 'onCreateTask', 'refresh'],
  data() {
    return {
      isEditingNotes: false,
      editedNotes: ''
    };
  },
  computed: {
    ...mapState({
      me: s => s.identity.email,
      mode: s => s.milestonesOverview.settings.taskViewMode.value,
      showColor: s => s.milestonesOverview.settings.colorCoding.value,
      displayMode: s => s.milestonesOverview.settings.displayMode.value?.toLowerCase()
    }),
    rowStyle() {
      var style = this.displayMode === 'standard' ? 'standard' : 'compact';

      if (this.displayMode === 'compact + notes' && this.nextTask?.notes) {
        style += ' top';
      }

      return style;
    },
    user() {
      return this.forUser || this.me;
    },
    nextTask() {
      return this.mode === 'My Next Task' ? this.item?.myNextTask || this.item?.nextTask : this.item?.nextTask;
    },
    inventionBackgroundColor() {
      if (this.showColor === 'Off') {
        return null;
      }

      const nextTask = this.nextTask;
      if (!nextTask) {
        return null;
      }
      const assignees = nextTask.assignees?.filter(a => a).map(a => a.toLowerCase().trim());
      if (!assignees?.length) {
        return null;
      }

      if (assignees.includes(this.user)) {
        if (nextTask.status === 'to do' || nextTask.status === 'in progress') {
          return 'rgba(0,128,0,0.2)';
        }
        if (assignees.length === 1) {
          return 'rgba(255,255,0,0.2)';
        }
      }
      if (assignees.includes('uspto')) {
        return 'rgba(128,0,128,0.2)';
      }
      if (assignees.includes('client')) {
        return 'rgba(255,165,0,0.4)';
      }
      if (assignees.includes('foreign counsel')) {
        return 'rgba(255,0,0,0.2)';
      }

      // check that assignees.has(@harrityllp.com)
      const includesHarrityAssignee = assignees.find(a => a.includes('@harrityllp.com'));
      if (includesHarrityAssignee) {
        return 'rgba(255,255,0,0.2)';
      }
      //otherwise no color
      return null;
    },
    awaiting() {
      const nextTask = this.nextTask;

      if (!nextTask?.assignees) {
        return null;
      }

      const assignees = nextTask.assignees.filter(a => a);

      if (!assignees.length) {
        return `not assigned`;
      }

      if (assignees.includes(this.user)) {
        if (nextTask.status === 'blocked' || nextTask.status === 'on hold') {
          return assignees.length === 1
            ? nextTask.status
            : `waiting on ${assignees
                .filter(a => a !== this.user)
                .map(a => `@${a.split('@')[0]}`)
                .join('; ')}`;
        }

        return null;
      }

      return `waiting on ${assignees.map(a => `@${a.split('@')[0]}`).join('; ')}`;
    },
    milestoneClientDate() {
      return this.nextTask?.workflow?.milestoneClientDueDate;
    },
    milestoneDueAt() {
      return this.nextTask.workflow?.milestoneDueAt;
    },
    milestoneTitle() {
      const nextTask = this.nextTask;

      if (!nextTask) {
        return this.item.nextMilestone?.title;
      }

      return nextTask.workflow.milestoneTitle || this.item.nextMilestone.title || 'No Milestone';
    },
    taskTitle() {
      const nextTask = this.nextTask;

      if (!nextTask) {
        return '';
      }

      const title = nextTask.title;

      if (!nextTask.dueAt) {
        return title;
      }

      var dueAt = new Date(nextTask.dueAt);
      var now = new Date();

      var day = dueAt.getDate();
      var month = dueAt.getMonth() + 1;
      var year = dueAt.getFullYear();

      return year == now.getFullYear() ? `${title} (${month}/${day})` : `${title} (${month}/${day}/${year})`;
    }
  },
  watch: {
    isExpanded: async function(newValue, oldValue) {
      if (!oldValue && !newValue) {
        return;
      }

      if (!this.item.id) {
        return;
      }

      if (newValue) {
        await this.$store.dispatch('tasks/subscribe', { inventionId: this.item.id });
      } else {
        await this.$store.dispatch('tasks/unsubscribe', { inventionId: this.item.id });
      }
    }
  },
  async beforeUnmount() {
    if (this.isExpanded) {
      await this.$store.dispatch('tasks/unsubscribe', { inventionId: this.item.id });
    }
  },
  methods: {
    async onBlur() {
      this.isEditingNotes = false;
      if (this.editedNotes !== this.nextTask?.notes) {
        const currentTask = await this.$store.dispatch('tasks/getById', this.nextTask.id);
        await this.$store.dispatch('tasks/update', { ...currentTask, notes: this.editedNotes });
        this.$emit('refresh');
      }
    },
    warningIcon(item) {
      const difference = differenceInHours(endOfDay(new Date(item.dueAt)), new Date());
      if (difference <= 24) {
        return 'fire';
      }

      if (difference <= 96 && difference > 24) {
        return 'alert-circle-outline';
      }

      return '';
    },
    onEditNotes() {
      if (this.isEditingNotes) {
        return;
      }
      this.isEditingNotes = true;
      this.editedNotes = this.nextTask?.notes;
      this.$nextTick(() => {
        const el = this.$refs.editor.$el;
        const textNode = this.$refs.editor.$el.nextSibling;
        const textNodeRects = textNode.getBoundingClientRect();
        const wrp = el.closest('.notes');
        const wrpRects = wrp.getBoundingClientRect();
        const shift = textNodeRects.y - wrpRects.y;
        el.style.top = `${-50 + shift}px`;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.invention-row {
  display: grid;
  border-bottom: 1px solid var(--theme-highlight);
  background: var(--theme-surface);
  border-radius: 2px;
  padding: 5px 0;

  cursor: pointer;

  align-content: center;
  align-items: center;
  grid-template-columns: 30px minmax(200px, 0.2fr) 0.7fr 0.7fr 1fr 80px 80px;

  &.standard {
    min-height: 45px;
  }

  &.compact {
  }

  &.top {
    align-items: flex-start;
  }

  .notes-placeholder {
    display: none;

    font-size: 0.75rem;
    letter-spacing: 0.03rem;
    opacity: 0.2;
  }

  &:hover {
    .notes-placeholder {
      display: block;
    }
  }

  .milestone-title {
    font-weight: 500;

    overflow: hidden;
    word-wrap: unset;
    white-space: nowrap;
    margin-right: 5px;
    text-overflow: ellipsis;
  }

  .task-title {
    color: var(--theme-on-surface-accent);
    font-weight: 500;

    overflow: hidden;
    word-wrap: unset;
    white-space: nowrap;
    text-overflow: ellipsis;

    margin-right: 5px;

    .awaiting {
      font-size: 0.75rem;
      font-weight: 400;
      opacity: 0.7;
      font-style: italic;
      color: var(--theme-on-primary-accent);
      letter-spacing: 0.02rem;
    }
  }

  .notes {
    font-style: italic;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    &:hover {
      cursor: text;
    }

    .transparent {
      opacity: 0;
    }

    .empty {
      min-height: 1rem;
    }

    .hub-editor {
      position: absolute;
      box-sizing: border-box;
      left: -11px;
      right: 0;
      z-index: 20;
      border: 1px solid var(--theme-highlight);

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        background-color: var(--theme-surface);
        opacity: 0.8;
      }
    }
  }

  .item-dueAt {
    color: var(--theme-on-surface-accent);
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 700;
    margin-right: 10px;

    &.left {
      justify-content: flex-end;
    }

    &.right {
      justify-content: flex-end;
      padding-left: 15px;
      border-left: 2px solid var(--theme-on-surface-accent);
    }
  }

  .add-task-wrapper {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    height: 30px;

    .add-task-button {
      font-size: 0.7rem;
      margin-right: 0.25rem;
      margin-left: 0.25rem;
      height: 30px;
      &:hover {
        cursor: pointer;
        color: var(--theme-on-primary);
        background: var(--theme-primary-accent);
      }
    }
  }

  .add-task {
    &:hover {
      cursor: pointer;
      color: var(--theme-on-primary);
      border-radius: 15%;
      background: var(--theme-on-background);
    }
  }
  .warning-icon {
    color: var(--theme-warning);
    margin-right: 5px;
  }
  .trim {
    padding-right: 0.3rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 100%;
  }
  .top {
    align-self: flex-start;
  }

  &:hover {
    cursor: pointer;
  }

  .expander {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1px;
  }

  .references {
    margin-right: 10px;
  }

  .invention-list-item-title {
    color: var(--theme-on-surface-accent);
    align-items: center;
    gap: 5px;
    padding-right: 10px;
  }

  .blue {
    color: var(--theme-primary);
  }
}
</style>
