<template>
  <div class="task-list-status">
    <hub-menu
      v-if="transitionsForTask(task).length"
      :options="transitionsForTask(task)"
      :test-id="task.title"
      class="task-status"
      @click="$e => onStatusChange($e)"
    >
      <hub-task-status :status="task.status"></hub-task-status>
    </hub-menu>
    <div v-else class="status-wrapper task-status">
      <hub-task-status :status="task.status" />
    </div>
    <span v-if="withStatusText" class="title">
      {{ task.status }}
    </span>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Menu from '@/components/common/Menu.vue';
import TaskStatus from '@/components/reports/components/TaskStatus.vue';
import findStatusIcon from '@/utils/findStatusIcon';

export default {
  components: {
    'hub-task-status': TaskStatus,
    'hub-menu': Menu
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    withStatusText: {
      type: Boolean,
      default: false
    }
  },
  emits: ['statusChanged'],
  computed: {
    ...mapState({
      milestoneTemplates: s => s.milestones.templates,
      templateCollection: s => s.tasks.templateCollection,
      bots: s => s.bots,
      email: s => s.identity.email
    }),
    transitionsForTask() {
      return task => {
        let transitions = [];
        const workflowTemplate = this.milestoneTemplates[task.workflow.id];

        if (!workflowTemplate && task.workflow.id) {
          return [];
        }
        if (workflowTemplate && (workflowTemplate.isRequestPending || workflowTemplate.isRequestFailed)) {
          return [];
        }
        if (workflowTemplate && workflowTemplate.template) {
          const milestoneTemplate = workflowTemplate.template.find(template => template.id === task.workflow.milestoneTemplateId);
          if (milestoneTemplate) {
            const step = milestoneTemplate.steps.find(step => step.id === task.workflow.stepId);
            if (step) {
              transitions = step.transitions.filter(t => t.enabled && t.current === task.status);
            }
          }
        }
        if (!transitions.length && this.templateCollection.length) {
          const currentTemplate = this.templateCollection.find(t => t.type === 'generic');
          transitions = currentTemplate?.transitions?.filter(t => t.current === task.status);
        }
        if (!transitions.length) {
          return [];
        }
        return transitions.map(t => {
          const [userLabel, botLabel] = t.label.split('|');

          const assignees = this.task.assignees || [];
          let bot = assignees.find(assignee => this.bots.includes(assignee));

          let botName = bot && bot.split('@')[0];
          const isMyTask = assignees.includes(this.email);
          const isBotTask = bot && isMyTask && t.next === 'in progress';
          let label = userLabel;
          if (botLabel && isBotTask) {
            label = `${botLabel} ${botName}`;
          }

          return { label, key: t.next, icon: findStatusIcon(t.next, isBotTask) };
        });
      };
    }
  },
  methods: {
    onStatusChange($e) {
      this.$emit('statusChanged', $e);
    }
  }
};
</script>
<style lang="scss" scoped>
.task-list-status {
  display: flex;
  overflow: visible;
  padding-left: 0;
  padding-right: 0;
  user-select: none;

  &:hover {
    .menu-dropdown {
      background-color: var(--theme-on-surface-accent);
    }
  }

  .status-wrapper {
    padding: 5px;
  }
  .title {
    color: var(--theme-primary);
    text-transform: uppercase;
  }
  .task-status {
    display: flex;
    align-items: center;
  }
}
</style>
