<template>
  <Loading v-if="progress" />
  <form v-else :class="{ dirty: v$.$anyDirty }" autocomplete="off" class="create-or-edit-message-form" @submit.stop.prevent>
    <section class="create-or-edit-message-form-body">
      <section style="padding: 0.3rem 0">
        <references :invention="{ id: invention.id, references: invention.references, title: 'Open Invention' }" />
      </section>
      <hr />
      <div v-if="!canEdit" class="form-row not-enough-rights-label">
        <span>You don't have enough permissions to edit this task</span>
      </div>

      <section :class="{ disabled: !canEdit }">
        <template v-if="workflow && workflow.milestoneId">
          <div class="form-row milestone-desc">
            <span>
              <b>Milestone:</b> <i>"{{ workflow.milestoneTitle }}"</i>
            </span>
            <span v-if="workflow.milestoneAssignees">
              , <b>assigned to:</b> <i>{{ workflow.milestoneAssignees.join(', ') }}</i>
            </span>
            <span v-if="milestoneClientDueDate">
              , <b>client due date:</b> <i>{{ milestoneClientDueDate }}</i>
            </span>
            <span v-if="milestoneDate">
              , <b>due at:</b> <i>{{ milestoneDate }}</i>
            </span>
          </div>
        </template>
        <template v-else>
          <div v-if="!isSelectingMilestone" class="form-row center">
            <span style="margin-right: 20px"> No milestone </span>
            <p-button v-if="actions.length && item && canEdit" color="primary" @click="switchToSelectMilestone">
              {{ item.createNew ? 'Assign' : 'Move' }} to milestone
            </p-button>
          </div>
          <div v-else class="milestone-select-wrapper">
            <p-multiselect v-model:value="selectedMilestone" :options="milestonesList" :disabled="!canEdit" />
            <p-button v-if="canEdit" @click="cancelSelectingMilestone">Cancel</p-button>
          </div>
        </template>
      </section>
      <hr />
      <div class="form-row">
        <hub-icon v-if="isCriticalDateHasUnsyncedChanges" color="warning" class="warning-mark" name="alert-circle-outline" />

        <hub-checkbox v-model:value="isCriticalDate" :label="'Critical task'" :disabled="!canEdit" />
      </div>
      <div class="form-row">
        <hub-icon v-if="titleHasUnsyncedChanges" color="warning" class="warning-mark" name="alert-circle-outline" />

        <p-text-field v-model="title" :test-id="'task-title'" label="Title" autocomplete="off" :disabled="!canEdit" />
        <ul v-if="v$.title.$invalid">
          <li v-if="v$.title.required.$invalid" class="error">Title is required</li>
        </ul>
      </div>
      <div class="form-row-double">
        <div class="left">
          <hub-icon v-if="dueAtHasUnsyncedChanges" color="warning" class="warning-mark more-margin" name="alert-circle-outline" />
          <div v-if="canEdit" class="form-row-action-list">
            <p-button type="button" variant="text" color="primary" @click.stop.prevent="setDueAt('+1d')">+1d</p-button>
            <p-button type="button" variant="text" color="primary" @click.stop.prevent="setDueAt('+1w')">+1w</p-button>
            <p-button type="button" variant="text" color="primary" @click.stop.prevent="setDueAt('+1m')">+1m</p-button>
          </div>
          <hub-date
            ref="dueAtComponent"
            v-model:value="dueAt"
            label="Due At"
            :disabled="!canEdit"
            :event-subject="`${title} (${subjectEventReferences})`.trim()"
            :event-body="`<p>${invention.references?.join(' | ')}</p><p>Open in <a href='${inventionUrl}'}>PatentHub</a></p>`"
            :calendar-event="metadata && metadata.calendars && metadata.calendars[calendarKey]"
            @calendar-event-created="onCalendarEventCreated"
          />
        </div>
        <p-text-field
          v-model="durationHours"
          :label="'Expected duration (hours)'"
          type="number"
          autocomplete="off"
          :disabled="!canEdit"
          :limits="{ min: 0 }"
        />
      </div>
      <div class="form-row">
        <div class="claim-button-group">
          <p-button v-if="canEdit && canAddSelf" variant="text" class="claim-button" color="primary" @click.stop.prevent="addMe">add me</p-button>
          <div v-if="canEdit && canClaim && canAddSelf" class="claim-button">|</div>
          <p-button v-if="canEdit && canClaim" class="claim-button" variant="text" color="primary" @click.stop.prevent="claim">assign to me</p-button>
        </div>
        <hub-icon v-if="assigneesHasUnsyncedChanges" class="warning-mark assignees" color="warning" name="alert-circle-outline" />

        <p-assignees v-model:value="assignees" label="Assigned to" placeholder="" :disabled="!canEdit" />
      </div>
      <div class="form-row">
        <hub-icon v-if="attachmentsHasUnsyncedChanges" class="warning-mark more-margin" color="warning" name="alert-circle-outline" />
        <FilePicker
          v-model:value="attachments"
          :label="'Attachments'"
          :reference="matterReference"
          :test-id="'attachemts'"
          :disabled="!canEdit || !matterReference"
          :multiple="true"
        />
      </div>
      <div class="form-row">
        <label>Attached emails</label>
        <EmailPicker v-model="emailAttachments" :invention-id="invention.id" />
      </div>
      <div class="form-row">
        <hub-icon v-if="notesHasUnsyncedChanges" color="warning" class="warning-mark" name="alert-circle-outline" />
        <label>Notes</label>
        <hub-editor v-model="notes" class="task-notes" :disabled="!canEdit" />
      </div>
      <hr />
      <div v-if="!item?.createNew" class="form-row tabs-pane">
        <div class="tabs-wrapper">
          <div v-if="hasAccessToInstructions" class="tab" :class="{ selected: selectedView === 'instructions' }" @click="selectTab('instructions')">
            <span> Instructions </span>
          </div>
          <div v-if="hasAccessToInstructions" class="tab-splitter">|</div>
          <div class="tab" :class="{ selected: selectedView === 'history' }" @click="selectTab('history')">History</div>
        </div>
        <div>
          <div v-if="$hasPermission('workflows') && selectedView === 'instructions'" class="instruction-control-buttons">
            <div>
              <span v-if="isEditingInstructions">{{ templateEditHeader }} - Instructions</span>
            </div>
            <p-button v-if="!isEditingInstructions" type="icon" title="Edit instructions" @click.stop="isEditingInstructions = true">
              <hub-icon class="instructions-edit-button" name="pencil" size="sm" />
            </p-button>
            <div v-else>
              <p-button v-if="instructionsContent !== instructions" type="icon" title="Save instructions" @click="saveInstructions">
                <hub-icon name="check" />
              </p-button>
              <p-button type="icon" title="cancel editing instructions" @click="canelEditingInstructions">
                <hub-icon name="close" />
              </p-button>
            </div>
          </div>
          <hub-task-history v-if="selectedView === 'history'" :task-id="item.id" :invention="invention" />
          <hub-editor
            v-if="selectedView === 'instructions' && !stepTemplateMissing"
            v-model="instructionsContent"
            :fade-on-disabled="false"
            :disabled="!isEditingInstructions"
            class="disabled-content"
            :enabled-optional-plugins="['video']"
            :class="{ edit: isEditingInstructions }"
          />
        </div>
      </div>
      <div v-if="isRequestFailed" class="error" style="display: block">
        {{ errorMessage || 'Failed to save a task. Check your input and try again.' }}
      </div>
    </section>
    <hr v-if="canEdit" />
    <section class="create-or-edit-message-form-footer">
      <template v-if="unsyncedChanges.length">
        <span style="display: flex; align-items: center">
          <hub-icon color="warning" name="alert-circle-outline" style="margin-right: 10px" />
          {{ item.updatedBy && item.updatedBy.split('@')[0] }} edited this task with some edits that conflict with yours.
        </span>
        <div>
          <p-button type="button" color="primary" @click="solve">Resolve conflicts</p-button>
        </div>
      </template>
      <template v-else>
        <div v-if="canEdit">
          <div v-if="stepTemplateMissing && actions?.length">
            <hub-icon
              name="alert-circle-outline"
              size="md"
              color="warning"
              title="Task template is missing. Generic template applied for this task"
            />
          </div>
          <p-button
            v-for="action in actions"
            :key="action.name"
            :title="action.description"
            type="button"
            color="primary"
            :test-id="action.label"
            :disabled="isRequestPending || (v$.$anyDirty && v$.$invalid) || !canEdit"
            @click.prevent="submit(action.name)"
            >{{ action.label }}</p-button
          >
        </div>
      </template>
    </section>
  </form>
  <merge-task-modal v-if="conflicts" :conflicts="conflicts" @close="conflicts = null" @submit="onConflictsResolved" />
</template>

<script>
/* eslint-disable vue/one-component-per-file */
import { h, createApp } from 'vue';
import { mapState } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { format } from 'date-fns';

import TextField from '@/components/common/TextField';
import Multiselect from '@/components/common/Multiselect';
import Checkbox from '@/components/common/Checkbox';

import Button from '@/components/common/Button';
import Assignees from '@/components/Assignees';
import DateField from '@/components/common/DateField';
import Icon from '@/components/common/Icon';
import Confirm from '@/components/common/Confirm';
import TaskHistory from './TaskHistory';
import FilePicker from '@/components/common/filepicker/FilePicker';
import EmailPicker from '@/components/common/EmailPicker';

import remoteSettings from '@/utils/rs';

import MergeTaskModal from './MergeTaskPopup';

import Editor from '@/components/common/editor/Editor';
import ReferenceList from '@/components/common/ReferenceList.vue';
import Loading from '@/components/common/Loading';

const SKIP_ACTION_CONFIRMATION = 'SKIP_ACTION_CONFIRMATION';

const compareArrays = (a1 = [], a2 = []) => {
  const t1 = a1?.filter(a => a && typeof a === 'string') || [];
  const t2 = a2?.filter(a => a && typeof a === 'string') || [];

  if (t1.length !== t2.length) {
    return false;
  }

  return !t1.some(assignee => !t2.includes(assignee));
};

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'hub-checkbox': Checkbox,
    'p-assignees': Assignees,
    'hub-date': DateField,
    'hub-icon': Icon,
    'p-multiselect': Multiselect,
    'hub-task-history': TaskHistory,
    MergeTaskModal,
    'hub-editor': Editor,
    references: ReferenceList,
    FilePicker,
    Loading,
    EmailPicker
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    invention: {
      type: Object,
      required: true
    },
    templateCollection: {
      type: Array,
      required: true
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    },
    workflow: {
      type: Object,
      default: () => null
    },
    errorMessage: {
      type: String,
      default: () => null
    },
    transitions: {
      type: Array,
      default: () => []
    },
    instructions: {
      type: String,
      default: ''
    }
  },
  emits: ['submit', 'changed'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    if (this.item) {
      if (this.item.createNew) {
        this.$nextTick(() => this.claim());
      }
      const attachments =
        this.item.attachments && Array.isArray(this.item.attachments)
          ? this.item.attachments?.filter(a => a)
          : this.item.attachments
          ? [this.item.attachments]
          : [];
      return {
        initialValues: {
          title: this.item.title,
          assignees: this.item.assignees?.filter(a => a && typeof a === 'string') || [],
          attachments: attachments,

          dueAt: this.item.dueAt ? new Date(this.item.dueAt) : null,
          notes: this.item.notes,
          isCriticalDate: this.item.isCriticalDate
        },
        title: this.item.title,
        status: this.item.status,
        assignees: this.item.assignees?.filter(a => a && typeof a === 'string') || [],
        dueAt: this.item.dueAt ? new Date(this.item.dueAt) : null,
        notes: this.item.notes,
        metadata: this.item.metadata || { calendars: {} },
        durationHours: this.item.durationHours || 0,
        isCriticalDate: this.item.isCriticalDate,
        isSelectingMilestone: false,
        selectedMilestone: null,
        conflicts: null,
        canEdit: this.item.canEdit === undefined || this.item.canEdit,
        instructionsContent: this.instructions,
        selectedView: this.instructions ? 'instructions' : 'history',
        attachments: attachments,
        isEditingInstructions: false,
        progress: false,
        emailAttachments: this.item.emailAttachments
      };
    }
    this.$nextTick(() => this.claim());
    return {
      initialValues: {},
      title: '',
      status: '',
      assignees: [],
      dueAt: '',
      notes: '',
      isCriticalDate: false,
      metadata: {
        calendars: {}
      },
      durationHours: 0,
      isSelectingMilestone: false,
      selectedMilestone: null,
      conflicts: null,
      canEdit: true,
      instructionsContent: this.instructions,
      selectedView: this.instructions ? 'instructions' : 'history',
      attachments: [],
      progress: false
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email,
      userId: s => s.identity.userId,
      userTeams: s => s.identity.teams,
      details: s => s.reports.myInventions.details,
      milestonesList() {
        return this.details[this.invention.id]?.milestones
          .filter(m => m.workflow.id !== this.item.workflow.id)
          .map(({ title, workflow }) => {
            return {
              label: title,
              value: {
                milestoneId: workflow.milestoneId,
                id: workflow.id,
                templateId: workflow.milestoneTemplateId
              }
            };
          });
      },
      step: s => s.steps.item,
      stepTemplateMissing() {
        return !this.item?.createNew && this.workflow?.milestoneId && !this.step;
      },
      canClaim() {
        const lowerCaseAssignees = this.assignees.map(a => a.toLowerCase());
        const lowerCaseEmail = this.email.toLowerCase();
        return !(lowerCaseAssignees.length === 1 && lowerCaseAssignees[0] === lowerCaseEmail);
      },
      canAddSelf() {
        const lowerCaseAssignees = this.assignees.map(a => a.toLowerCase());
        const lowerCaseEmail = this.email.toLowerCase();
        return !lowerCaseAssignees.includes(lowerCaseEmail);
      },
      templateEditHeader: function (s) {
        if (!this.workflow || !this.workflow.templateId) {
          return;
        }
        const workflowTemplate = s.milestones.templates[this.workflow.id];
        if (!workflowTemplate) {
          return '';
        }
        const milestone = workflowTemplate.template.find(m => m.id === this.workflow.templateId);

        if (!milestone) {
          return '';
        }
        return `${workflowTemplate.name} - ${milestone.title} - ${this.step?.step.title}`.toUpperCase();
      }
    }),
    actions() {
      const collection = [];
      if (this.item && !this.item.createNew) {
        collection.push(...this.transitions.map(t => ({ next: t.next, label: t.label, description: t.description })));
      } else {
        collection.push({ next: '', label: 'Save' });
      }

      return collection.map(s => ({ name: s.next, label: s.label || s.next, description: s.description }));
    },
    milestoneDate() {
      return this.workflow.milestoneDueAt && format(new Date(this.workflow.milestoneDueAt), 'MM/dd/yyyy');
    },
    milestoneClientDueDate() {
      return this.workflow.milestoneClientDueDate && format(new Date(this.workflow.milestoneClientDueDate), 'MM/dd/yyyy');
    },
    dueAtHasUnsyncedChanges() {
      if (!this.item.dueAt) {
        return false;
      }
      const serverTime = +new Date(this.item.dueAt);
      return +this.dueAt !== serverTime && +this.initialValues.dueAt !== +serverTime;
    },
    titleHasUnsyncedChanges() {
      return this.title !== this.item.title && this.initialValues.title !== this.item.title;
    },
    notesHasUnsyncedChanges() {
      return this.notes !== this.item.notes && this.initialValues.notes !== this.item.notes;
    },
    isCriticalDateHasUnsyncedChanges() {
      return this.isCriticalDate !== this.item.isCriticalDate && this.initialValues.isCriticalDate !== this.item.isCriticalDate;
    },
    assigneesHasUnsyncedChanges() {
      return !compareArrays(this.assignees, this.item.assignees) && !compareArrays(this.initialValues.assignees, this.item.assignees);
    },
    attachmentsHasUnsyncedChanges() {
      const thisItemAttachments = Array.isArray(this.item.attachments) ? this.item.attachments : [this.item.attachments];
      return !compareArrays(this.attachments, thisItemAttachments) && !compareArrays(this.initialValues.attachments, thisItemAttachments);
    },
    unsyncedChanges() {
      return ['notes', 'dueAt', 'assignees', 'title', 'isCriticalDate', 'attachments'].filter(field => this[`${field}HasUnsyncedChanges`]);
    },
    hasChanges() {
      const serverTime = this.item.dueAt ? +new Date(this.item.dueAt) : 0;
      const thisItemAttachments = Array.isArray(this.item.attachments) ? this.item.attachments : [this.item.attachments];
      return (
        this.title !== this.item.title ||
        this.notes !== this.item.notes ||
        this.isCriticalDate !== this.item.isCriticalDate ||
        !compareArrays(this.assignees, this.item.assignees) ||
        !compareArrays(this.attachments, thisItemAttachments) ||
        +this.dueAt !== serverTime ||
        this.selectedMilestone
      );
    },
    matterReference() {
      return this.invention.references?.find(ref => ref.toLowerCase().startsWith('harrity:'));
    },
    inventionUrl() {
      return `${window.location.origin}/inventions/${this.invention.id}`;
    },
    subjectEventReferences() {
      if (this.invention.references?.length) {
        return this.invention.references.map(e => (e.includes(':') ? e.split(':')[1] : e)).join(' | ');
      }
      return '';
    },
    hasAccessToInstructions() {
      if (this.stepTemplateMissing) {
        return false;
      }

      if (this.instructions) {
        return true;
      }
      if (this.workflow && this.workflow.stepId && this.$hasPermission('workflows')) {
        return true;
      }
      return false;
    },
    calendarKey() {
      return `${this.userId}____task`;
    }
  },
  watch: {
    async item(newValue, oldValue) {
      if (this.notes === this.initialValues.notes || this.notes === newValue.notes) {
        this.notes = newValue.notes;
        this.initialValues.notes = newValue.notes;
      }

      if (this.title === this.initialValues.title || this.title === newValue.title) {
        this.title = newValue.title;
        this.initialValues.title = newValue.title;
      }

      if (this.isCriticalDate === this.initialValues.isCriticalDate || this.isCriticalDate === newValue.isCriticalDate) {
        this.isCriticalDate = newValue.isCriticalDate;
        this.initialValues.isCriticalDate = newValue.isCriticalDate;
      }

      const newDueAtFromServer = newValue.dueAt ? new Date(newValue.dueAt) : null;
      const oldDueAtFromServer = oldValue.dueAt ? new Date(oldValue.dueAt) : null;

      if (+this.dueAt === +oldDueAtFromServer || +this.dueAt === +newDueAtFromServer) {
        this.dueAt = newDueAtFromServer;
        this.initialValues.dueAt = newDueAtFromServer;
      }

      if (compareArrays(this.assignees, this.initialValues.assignees || compareArrays(this.assignees, newValue.assignees))) {
        this.assignees = newValue.assignees;
        this.initialValues.assignees = newValue.assignees;
      }

      if (compareArrays(this.attachments, this.initialValues.attachments || compareArrays(this.attachments, newValue.attachments))) {
        this.attachments = newValue.attachments;
        this.initialValues.attachments = newValue.attachments;
      }

      this.$store.dispatch('tasks/updateTaskHistory', this.item.id);
    },
    hasChanges(newValue, oldValue) {
      this.$emit('changed', newValue !== oldValue);
    },
    instructions(nw) {
      this.instructionsContent = nw;
    }
  },
  created() {
    this.$store.dispatch('tasks/reset');
  },
  validations() {
    return {
      title: { required }
    };
  },

  methods: {
    selectTab(tab) {
      this.selectedView = tab;
      this.isEditingInstructions = false;
      this.instructionsContent = this.instructions;
    },
    onConflictsResolved(values) {
      values.forEach(({ field, value }) => {
        this[field] = value;
        this.initialValues[field] = this.item[field];
      });
      this.conflicts = null;
    },
    solve() {
      this.conflicts = {
        editor: this.item.updatedBy.split('@')[0],
        conflicts: ['title', 'dueAt', 'assignees', 'notes', 'isCriticalDate'].reduce((acc, field) => {
          if (this[`${field}HasUnsyncedChanges`]) {
            acc.push({ field, your: this[field], their: this.item[field] });
          }
          return acc;
        }, [])
      };
    },
    cancelSelectingMilestone() {
      this.isSelectingMilestone = false;
      this.selectedMilestone = null;
    },
    claim() {
      this.assignees = [this.email];
    },
    addMe() {
      if (this.assignees.includes(this.email)) {
        return;
      }

      this.assignees = [...this.assignees, this.email];
    },
    setDueAt(mod) {
      const value = this.applyDateMod(mod, new Date());
      if (value) {
        this.dueAt = value;
      }
    },
    applyDateMod(mod, value = new Date()) {
      switch (mod) {
        case '+1d':
          const tomorrow = new Date(value);
          tomorrow.setDate(value.getDate() + 1);
          return tomorrow;
        case '+1w':
          const inWeek = new Date(value);
          inWeek.setDate(value.getDate() + 7);
          return inWeek;
        case '+1m':
          const inMonth = new Date(value);
          inMonth.setMonth(value.getMonth() + 1);
          return inMonth;
        default:
          return null;
      }
    },
    async submit(status) {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const date = this.$refs.dueAtComponent;

      try {
        this.progress = true;
        const originalAssignees = this.item.assignees?.filter(a => a) || [];

        if (!(await this.confirmNotYourTask(originalAssignees))) {
          return;
        }

        const item = this.actions.find(({ name }) => name === status);

        if (!(await this.confirmAction(item))) {
          return;
        }

        let workflow = this.item.workflow;
        if (this.selectedMilestone) {
          workflow = this.selectedMilestone;
        }

        await date.syncIfNeeded().then(calendarEvent => this.onCalendarEventCreated(calendarEvent));

        this.$emit('submit', {
          title: this.title,
          type: this.type,
          status: status,
          assignees: this.assignees,
          dueAt: this.dueAt,
          notes: this.notes,
          isCriticalDate: this.isCriticalDate,
          workflow,
          metadata: this.metadata,
          durationHours: this.durationHours,
          attachments: this.attachments,
          emailAttachments: this.emailAttachments
        });
        this.$trackEvent(this.status === status ? `Task saved` : 'Task action applied');
        this.v$.$reset();
      } finally {
        this.progress = false;
      }
    },
    async onCalendarEventCreated(calendarEvent) {
      if (!calendarEvent) {
        return;
      }

      if (!this.metadata.calendars) {
        this.metadata.calendars = {};
      }
      try {
        const key = this.calendarKey;
        const updatedTask = await this.$store.dispatch('tasks/update', {
          ...this.item,
          assignees: this.item.assignees || [],
          metadata: { ...this.metadata, calendars: { ...this.metadata.calendars, [key]: calendarEvent } }
        });
        this.metadata.calendars[key] = calendarEvent;
      } catch (e) {}
    },
    async confirmNotYourTask(assignees) {
      if (assignees?.length && !assignees.includes(this.email) && !assignees.find(a => this.userTeams.includes(a))) {
        const result = await this.requestConfirmation(
          {
            title: 'This task is not assigned to you',
            message: 'This task is not assigned to you. Are you sure you want to edit it?'
          },
          false
        );

        return result;
      }

      return true;
    },
    async confirmAction(item) {
      if (item && item.description) {
        const skip = (await remoteSettings.get(SKIP_ACTION_CONFIRMATION)) || [];

        if (!skip.includes(item.name)) {
          const result = await this.requestConfirmation({
            message: item.description
          });

          if (!result) {
            return false;
          }

          if (result.doNotShowAgain) {
            await remoteSettings.set(SKIP_ACTION_CONFIRMATION, [...skip, item.name]);
          }
        }
      }

      return true;
    },
    async requestConfirmation(props, doNotShowAgainVisible = true, store) {
      return new Promise(resolve => {
        const closeWithResult = result => {
          instance.unmount();
          resolve(result);
        };

        const instance = createApp({
          render() {
            return h(Confirm, {
              ...props,
              doNotShowAgainVisible,
              onCancel() {
                closeWithResult();
              },
              onConfirm(result) {
                closeWithResult(result);
              }
            });
          }
        });

        instance.mount(document.getElementById('modal'));
      });
    },
    async switchToSelectMilestone() {
      this.isSelectingMilestone = true;
    },
    canelEditingInstructions() {
      this.isEditingInstructions = false;
      this.instructionsContent = this.instructions;
    },
    async saveInstructions() {
      try {
        await this.$store.dispatch('steps/updateStepInstructions', {
          workflowId: this.workflow.id,
          milestoneId: this.workflow.templateId,
          stepId: this.item.workflow.stepId,
          instructions: this.instructionsContent
        });
        this.isEditingInstructions = false;
        this.$toast.success({
          title: 'Update completed.',
          message: `Instructions were updated.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to save instructions',
          message: `Please, try again later or contact our development team.`
        });
      }
      await this.$store.dispatch('steps/getById', {
        workflowId: this.workflow.id,
        milestoneId: this.workflow.milestoneId,
        stepId: this.workflow.stepId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.025em;
}
.create-or-edit-message-form {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr auto;
  background: var(--theme-surface);
  min-height: 100%;
  height: 100%;

  hr {
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .instruction-control-buttons {
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .warning-mark {
    position: absolute;
    top: 50%;
    right: 15px;
    z-index: 1;
    &.assignees {
      right: 35px;
    }
    &.more-margin {
      right: 40px;
    }
  }

  .create-or-edit-message-form-body {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: repeat(12, max-content);
    min-height: 0;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 3px;
  }
  .create-or-edit-message-form-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    vertical-align: bottom;
    padding: 0.5rem var(--theme-scroll-width) 0.25rem 0;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > * {
        margin-left: 0.5rem;
      }
    }
  }
  .form-row {
    position: relative;
    margin-bottom: 0.3rem;
    &.center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .claim-button-group {
      position: absolute;
      right: 0;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: flex-end;

      .claim-button {
        font-size: 0.7rem;
        cursor: pointer;
      }
    }

    &.milestone-desc {
      b {
        font-weight: 600;
      }
      i {
        font-style: italic;
      }
    }
  }

  .form-row-double {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0.3rem;
    gap: 15px;
    align-items: center;

    .left {
      position: relative;

      .form-row-action-list {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;

        > button {
          font-size: 0.6rem;
          padding-right: 0;
          padding-left: 0;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .milestone-select-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 20px;
  }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
    margin: 0;
  }
  &.dirty {
    .error {
      display: block;
    }
  }

  .reference-list-item {
    font-size: 0.85rem;
    line-height: 1.2;
    font-weight: 500;
    // display: inline-flex;
    // align-items: center;

    &:not(:last-child) {
      &:after {
        content: '|';
        color: var(--theme-on-surface);
        padding: 0rem 0.25rem;
        font-weight: 700;
      }
    }
  }
  .task-notes {
    margin-top: 5px;
    background: var(--theme-background);
    border: 1px solid var(--theme-on-background-accent);
  }

  .not-enough-rights-label {
    font-style: italic;
    color: var(--theme-warning);
    font-weight: 600;
  }
}

.tabs-pane {
  display: block;
  justify-content: space-between;
  background: var(--theme-background);

  .tabs-wrapper {
    display: flex;
    align-items: center;
    .tab {
      display: flex;
      user-select: none;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      color: var(--theme-on-default);
      padding: 2px 5px;
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.025em;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .tab-splitter {
      display: flex;
      user-select: none;
      padding: 2px 5px;
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.025em;
    }
  }

  .disabled-content {
    min-height: 150px;
    padding: 5px;
    &.edit {
      border: 1px solid var(--theme-on-background-accent);
      background-color: var(--theme-highlight);
    }
  }
}
</style>
