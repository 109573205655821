<template>
  <hub-modal :visible="true" class="metadata-modal">
    <template #title> {{ form.title }} </template>
    <template #footer>
      <p-button variant="text" @click="$emit('close')">Cancel</p-button>
      <p-button
        style="justify-self: start"
        type="button"
        color="primary"
        :disabled="v$.$anyDirty && v$.$invalid"
        :test-id="'continue-button'"
        @click.prevent.stop="submit()"
        >Continue</p-button
      >
    </template>
    <div v-if="isReady" class="metadata-modal-body">
      <form :class="{ dirty: v$.$anyDirty }" autocomplete="off" class="metadata-form" @submit.stop.prevent>
        <div v-for="field of form.properties.filter(f => f.type === 'people')" :key="field.name" class="form-row">
          <p-assignees
            :ref="el => addRef(el, field.property)"
            v-model:value="data[field.property]"
            :label="field.name"
            placeholder=""
            :test-id="field.name"
            :disabled="false"
          />
          <ul v-if="v$.data[field.property]">
            <li v-if="v$.data[field.property].required && v$.data[field.property].required.$invalid" class="error">This field is required</li>
          </ul>
        </div>
        <div v-for="field of form.properties.filter(f => f.type === 'date')" :key="field.name" class="form-row">
          <p-date-field
            :ref="el => addRef(el, field.property)"
            v-model:value="data[field.property]"
            :label="field.description || field.name"
            :test-id="field.name"
            placeholder=""
            :disabled="false"
            :limits="field.limits"
            :calendar-event-properties="field.calendar"
            :event-subject="
              `${field.description || field.name} (${
                task.invention?.references?.length ? task.invention?.references.map(e => (e.includes(':') ? e.split(':')[1] : e)).join(' | ') : ''
              })`.trim()
            "
            :event-body="`<p>${task.invention?.references?.join(' | ')}</p><p>Open in <a href='${inventionUrl}'}>PatentHub</a></p>`"
            :calendar-event="
              taskInstance &&
              taskInstance.metadata &&
              taskInstance.metadata.calendars &&
              taskInstance.metadata.calendars[`${userId}____form____${field.property}`]
            "
            @calendar-event-created="$e => onCalendarEventCreated(field, $e)"
          />
          <ul v-if="v$.data[field.property]">
            <li v-if="v$.data[field.property].required && v$.data[field.property].required.$invalid" class="error">This field is required</li>
          </ul>
        </div>
        <div v-for="field of form.properties.filter(f => f.type === 'datetime')" :key="field.name" class="form-row">
          <p-date-field
            :ref="el => addRef(el, field.property)"
            v-model:value="data[field.property]"
            mode="datetime"
            :label="field.description || field.name"
            :test-id="field.name"
            placeholder=""
            :disabled="false"
            :limits="field.limits"
            :calendar-event-properties="field.calendar"
            :event-subject="
              `${field.description || field.name} (${
                task.invention?.references?.length ? task.invention.references.map(e => (e.includes(':') ? e.split(':')[1] : e)).join(' | ') : ''
              })`.trim()
            "
            :event-body="`<p>${task.invention.references?.join(' | ')}</p><p>Open in <a href='${inventionUrl}'}>PatentHub</a></p>`"
            :calendar-event="
              taskInstance &&
              taskInstance.metadata &&
              taskInstance.metadata.calendars &&
              taskInstance.metadata.calendars[`${userId}____form____${field.property}`]
            "
            @calendar-event-created="$e => onCalendarEventCreated(field, $e)"
          />
          <ul v-if="v$.data[field.property]">
            <li v-if="v$.data[field.property].required && v$.data[field.property].required.$invalid" class="error">This field is required</li>
          </ul>
        </div>
        <div v-for="field of form.properties.filter(f => f.type === 'number' || f.type === 'string')" :key="field.name" class="form-row">
          <label class="label">{{ field.description || field.name }}</label>
          <p-text-field :ref="el => addRef(el, field.property)" v-model="data[field.property]" :test-id="field.name" class="task-notes" />
          <ul v-if="v$.data[field.property]">
            <li v-if="v$.data[field.property].required && v$.data[field.property].required.$invalid" class="error">This field is required</li>
          </ul>
        </div>
        <div v-for="field of form.properties.filter(f => f.type === 'file')" :key="field.name" class="form-row">
          <FilePicker
            :ref="el => addRef(el, field.property)"
            v-model:value="data[field.property]"
            :search="field.search"
            :label="field.description || field.name"
            :multiple="field.multiple"
            :test-id="field.name"
            :ext="field.ext"
            :disabled="false"
            :reference="task.invention.references ? task.invention.references[0] : null"
          />
          <ul v-if="v$.data[field.property]">
            <li v-if="v$.data[field.property].required && v$.data[field.property].required.$message" class="error">This field is required</li>
          </ul>
        </div>
        <div v-for="field of form.properties.filter(f => f.type === 'formPicker')" :key="field.name" class="form-row">
          <FormPicker
            :ref="el => addRef(el, field.property)"
            v-model:value="data[field.property]"
            :label="field.description || field.name"
            :tags="field.tags"
            :client-code="clientCode"
          />
          <ul v-if="v$.data[field.property]">
            <li v-if="v$.data[field.property].required && v$.data[field.property].required.$message" class="error">This field is required</li>
          </ul>
        </div>

        <div v-for="field of form.properties.filter(f => f.type === 'email')" :key="field.name" class="form-row">
          <EmailPicker :ref="el => addRef(el, field.property)" v-model="data[field.property]" :invention-id="task.invention.id" />
          <ul v-if="v$.data[field.property]">
            <li v-if="v$.data[field.property].required && v$.data[field.property].required.$message" class="error">This field is required</li>
          </ul>
        </div>
      </form>
    </div>
    <Loading v-else />
  </hub-modal>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapState } from 'vuex';

import Editor from '@/components/common/editor/Editor';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import Assignees from '@/components/Assignees';
import DateField from '@/components/common/DateField';
import FilePicker from '@/components/common/filepicker/FilePicker';
import FormPicker from '@/components/common/formpicker/FormPicker';
import EmailPicker from '@/components/common/EmailPicker';
import { isValid, parseISO } from 'date-fns';
import Loading from '@/components/common/Loading';

export default {
  components: {
    'p-text-field': Editor,
    'p-date-field': DateField,
    'p-button': Button,
    'p-assignees': Assignees,
    'hub-modal': Modal,
    FilePicker,
    FormPicker,
    Loading,
    EmailPicker
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    task: {
      type: Object,
      default: null
    }
  },

  emits: ['submit', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    const matterNumber = (this.task.invention && this.task.invention.references.find(r => r.startsWith('HARRITY:'))?.split(':')[1]) || '';
    const clientCode = matterNumber.split('-')[0];
    return {
      isReady: false,
      clientCode,
      references: {},
      data: this.form.properties.reduce((obj, key) => {
        switch (key.type) {
          case 'string':
          case 'number': {
            obj[key.property] = key.value || '';
            break;
          }
          case 'date':
          case 'datetime': {
            const value = key.value && parseISO(key.value);
            obj[key.property] = value && isValid(value) ? key.value : null;
            break;
          }
          case 'people': {
            const value = key.value;
            obj[key.property] = Array.isArray(value) ? value : [];

            break;
          }
          case 'file':
          case 'email': {
            obj[key.property] = key.value || [];
          }
        }
        return obj;
      }, {}),
      taskInstance: null
    };
  },
  computed: {
    inventionUrl() {
      return `${window.location.origin}/inventions/${this.task.invention?.id}`;
    },

    ...mapState({
      userId: s => s.identity.userId
    })
  },
  async created() {
    this.taskInstance = await this.$store.dispatch('tasks/getById', this.task.id);
    this.isReady = true;
  },
  validations() {
    return {
      data: this.form.properties.reduce((obj, key) => {
        if (key.required) {
          return {
            ...obj,
            [key.property]: { required }
          };
        } else {
          return obj;
        }
      }, {})
    };
  },
  methods: {
    addRef(el, property) {
      this.references[property] = el;
    },
    async submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const dateTimeProps = this.form.properties.filter(f => f.type === 'date' || f.type === 'datetime');
      const referencies = dateTimeProps.map(field => ({
        field,
        component: this.references[field.property]
      }));

      try {
        this.isReady = false;

        for (const reference of referencies) {
          await reference.component.syncIfNeeded().then(calendarEvent => this.onCalendarEventCreated(reference.field, calendarEvent));
        }

        this.$emit(
          'submit',
          this.form.properties.map(key => {
            if (key.type === 'file') {
              const value = this.data[key.property] || [];
              return { ...key, value: Array.isArray(value) ? value : [value] };
            } else {
              return { ...key, value: this.data[key.property] };
            }
          })
        );

        this.v$.$reset();
      } finally {
        this.isReady = true;
      }
    },
    async onCalendarEventCreated(field, calendarEvent) {
      if (!calendarEvent) {
        return;
      }

      if (!this.taskInstance.metadata) {
        this.taskInstance.metadata = {};
      }
      if (!this.taskInstance.metadata.calendars) {
        this.taskInstance.metadata.calendars = {};
      }

      try {
        const key = `${this.userId}____form____${field.property}`;
        await this.$store.dispatch('tasks/update', {
          ...this.taskInstance,
          assignees: this.taskInstance.assignees || [],
          metadata: { ...this.taskInstance.metadata, calendars: { ...this.taskInstance.metadata.calendars, [key]: calendarEvent } }
        });
        this.taskInstance = await this.$store.dispatch('tasks/getById', this.task.id);
      } catch (e) {}
    }
  }
};
</script>

<style lang="scss" scoped>
.metadata-modal-body {
  //   background: var(--theme-surface);
  padding: 1.5rem 2rem 0.5rem 2rem;

  .metadata-form {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: minmax(0, 1fr);

    min-height: 100%;

    .task-notes {
      margin-top: 5px;
      background: var(--theme-background);
      border: 1px solid var(--theme-on-background-accent);
    }

    .label {
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.025em;
    }

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
      margin: 0;
    }
    &.dirty {
      .error {
        display: block;
      }
    }
  }
}
</style>

<style>
.metadata-modal .modal {
  width: 600px;
  height: 610px;
  grid-template-rows: max-content 1fr max-content;
}

@media screen and (max-device-width: 1023px), screen and (max-width: 1023px) {
  .metadata-modal .modal {
    width: 100%;
    height: 100%;
  }
}
</style>
