<template>
  <section class="overview-wrapper">
    <header class="overview-header" :class="{ 'with-panel': settingsPanelShown }">
      <div class="filters">
        <TextField
          v-model="filter"
          class="filter"
          :clear-button="true"
          :debounce="true"
          :test-id="'milestone-overview-filter-input'"
          :placeholder="'Filter'"
          :initial-focus="true"
          @change="onFilterChanged"
        />
        <label>for</label>
        <Assignees
          v-model:value="assignee"
          :single="true"
          :shorthand="true"
          :mapper="prepareValue"
          placeholder=""
          :select-on-blur="true"
          @change="onAssigneeChange"
        />
        <hub-icon v-if="assignee !== me" class="me-icon" name="account-arrow-left" title="Me" @click="onAssigneeChange(me)" />
        <label>sorted by</label>
        <hub-multiselect
          v-model:value="sort"
          :can-deselect="false"
          :can-clear="false"
          :options="[
            { label: 'Client Date', value: 'cdd' },
            { label: 'Due Date', value: 'mda' }
          ]"
          @change="onSortChange"
        />
      </div>
      <!-- create task, create milesone dialogs -->
      <div v-if="isReady">
        <label v-if="collection.length > 0">{{ collection?.length }} of {{ total }}</label>
      </div>
    </header>
    <div class="column-headers" :class="{ 'with-panel': settingsPanelShown }">
      <div></div>
      <label>Invention</label>
      <label>Milestone</label>
      <div class="task-mode">
        <label>{{ mode }}</label>
      </div>
      <label>Notes</label>
      <label class="left">Client Date</label>
      <label class="right">Due Date</label>
    </div>

    <div v-if="!isReady" class="results-pane">
      <div class="status">
        <hub-icon v-if="!isReady" name="loading" spin size="md"></hub-icon>
        <label>Searching</label>
      </div>
    </div>
    <div v-else-if="failed || !collection?.length" class="results-pane">
      <div class="status">
        <hub-icon name="text-box-search-outline" size="md" />
        <label>Nothing found. Please clarify your query</label>
      </div>
      <div class="help">
        <div class="column">
          <div class="row header">If you're looking for...</div>
          <div class="row query">words <span class="request">draft</span> or <span class="request">response</span></div>
          <div class="row query">words <span class="request">draft</span> and <span class="request">response</span></div>
          <div class="row query">phrase <span class="request">non-final</span></div>
          <div class="row query">tasks where title contains the phrase<span class="request">draft response</span></div>
          <div class="row query">
            <pre>milestones with word <span class="request">response</span> within three words of <span class="request">action</span> in title</pre>
          </div>
          <div class="row query">
            <pre>milestones with word <span class="request">response</span> and not word <span class="request">action</span> in title</pre>
          </div>
          <div class="row query">you can group queries</div>
          <div class="row query">task title containing all combinations of word <span class="request">report</span></div>
        </div>
        <div class="column">
          <div class="row header">Try next filters...</div>
          <div class="row"><span class="example">draft OR response</span> or <span class="example">draft response</span></div>
          <div class="row"><span class="example">draft AND response</span> or <span class="example">"draft response"</span></div>
          <div class="row"><span class="example">"non-final"</span></div>
          <div class="row">
            <pre><span class="example">title: "draft response"</span> (options: <span class="example">notes</span>, <span class="example">workflow.milestoneTitle</span>)</pre>
          </div>
          <div class="row"><span class="example">workflow.milestoneTitle: "response action"~3</span></div>
          <div class="row"><span class="example">workflow.milestoneTitle: response NOT amendment</span></div>
          <div class="row"><span class="example">(response AND amendment) NOT final</span></div>
          <div class="row">
            <span class="example">title: report*</span> (<span class="example">*</span> - zero or many characters, <span class="example">?</span> -
            exactly one character)
          </div>
          <div class="row flex-end">
            <a href="https://lucene.apache.org/core/2_9_4/queryparsersyntax.html" target="_blank">more extreme syntax</a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="overview">
      <ul v-if="collection.length" ref="listRootRef" class="list overview-list">
        <transition-group name="flip-list">
          <li v-for="item of collection" :id="item.id" :key="item.id">
            <OverviewHeader :item="item" :is-expanded="expanded === item.id" :for-user="assignee" @click="toggleOverview(item)" @refresh="refresh" />
            <div v-if="expanded === item.id" class="detailes-wrapper" :style="{ height: currentItemHeight && `${currentItemHeight}px` }">
              <div class="details">
                <div v-if="!details[item.id] || details[item.id].isRequestPending" class="detailes-loading">
                  <hub-icon name="loading" spin size="lg"></hub-icon>
                </div>
                <div v-else-if="details[item.id].isRequestFailed" class="error">Failed to load milestone list</div>
                <Transition name="fade">
                  <div v-if="details[item.id] && !details[item.id].isRequestPending && !details[item.id].isRequestFailed">
                    <Detailes
                      class="detailes-fade"
                      :for-user="assignee"
                      :next-task="item.myNextTask || item.nextTask"
                      :invention="item"
                      :milestones="details[item.id].milestones"
                      @editTask="onEditTask"
                      @createTask="$e => onCreateTask(item, $e)"
                      @editMilestone="$e => editMilestone(item, $e)"
                    />
                  </div>
                </Transition>
              </div>
            </div>
          </li>
          <li v-if="running" key="spinner" class="details-loading">
            <hub-icon name="loading" spin size="lg"></hub-icon>
          </li>
        </transition-group>
        <li v-if="!running && total > collection.length" key="observer">
          <hub-observe :get-root-ref="() => $refs['listRootRef']" @intersect="more">
            <hub-button variant="text" @click="more">more<hub-icon name="refresh" spin></hub-icon></hub-button>
          </hub-observe>
        </li>
      </ul>
    </div>
    <TaskModal
      v-if="isReady"
      :selected="selected"
      :task-create-options="taskCreateOptions"
      @close="taskModalClosed"
      @createModalClose="onCreateTaskModalClosed"
      @edited="taskEdited"
    />
    <CreateMilestoneModal
      v-if="isMilestoneCreateModalVisible && isReady"
      :invention="milestoneCreateOptions.invention"
      :milestone="milestoneCreateOptions.milestone"
      @close="isMilestoneCreateModalVisible = false"
      @created="onMilestoneCreated"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import TextField from '@/components/common/TextField';

import Observer from '../inventions/Observer.vue';
import TaskModal from '../inventions/tasks/TaskModal.vue';
import Detailes from './components/Detailes.vue';
import CreateMilestoneModal from './components/CreateMilestoneModal.vue';
import Multiselect from '@/components/common/Multiselect.vue';
import OverviewHeader from './components/OverviewHeader.vue';
import Assignees from '@/components/Assignees';

const scrollTo = (difference, duration = 500, el) => {
  const startY = el.scrollTop;
  const startTime = performance.now();

  const step = () => {
    const progress = (performance.now() - startTime) / duration;
    const amount = easeOutCubic(progress);
    el.scrollTop = startY + amount * difference;
    if (progress < 0.99) {
      window.requestAnimationFrame(step);
    }
  };

  step();
};

// Easing function from https://gist.github.com/gre/1650294
const easeOutCubic = t => t;

const waiter = function() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, 300);
  });
};

export default {
  components: {
    'hub-icon': Icon,
    'hub-button': Button,
    'hub-observe': Observer,
    'hub-multiselect': Multiselect,
    TaskModal,
    Detailes,
    CreateMilestoneModal,
    OverviewHeader,
    Assignees,
    TextField
  },
  async beforeRouteLeave() {
    await this.$store.dispatch('tasks/disconnect');
  },
  data() {
    return {
      isReady: true,
      selected: null,
      taskCreateOptions: null,
      expanded: null,
      isCreateModalVisible: false,
      milestoneCreateOptions: null,
      isMilestoneCreateModalVisible: false,
      sort: 'cdd',
      timeoutRef: null,
      isRefreshing: false,
      assignee: '',
      filter: '',
      currentItemHeight: null
    };
  },
  computed: {
    ...mapState({
      running: s => s.milestonesOverview.isRequestPending,
      failed: s => s.milestonesOverview.isRequestFailed,
      collection: s => s.milestonesOverview.data,
      total: s => s.milestonesOverview.total,
      details: s => s.milestonesOverview.details,
      mode: s => s.milestonesOverview.settings.taskViewMode.value,
      me: s => s.identity.email,
      settingsPanelShown: s => s.settings.panelShown,
      settings: s => s.milestonesOverview.settings
    })
  },
  watch: {
    collection(nw, ow) {
      if (!this.expanded) {
        return;
      }
      const oldExpandedIndex = ow.findIndex(item => item.id === this.expanded);
      const newExpandedIndex = nw.findIndex(item => item.id === this.expanded);
      if (newExpandedIndex > -1 && oldExpandedIndex > -1 && oldExpandedIndex === newExpandedIndex) {
        return;
      }

      const expandedContainer = document.getElementById(this.expanded);
      if (!expandedContainer) {
        return;
      }

      const sibling = expandedContainer.nextSibling || expandedContainer.previousSibling;
      const parent = expandedContainer.parentElement;

      const indexDiff = newExpandedIndex - oldExpandedIndex;
      if (indexDiff !== 0 && sibling) {
        const height = sibling.clientHeight;
        const difference = height * indexDiff;
        scrollTo(difference, 1000, parent);
      }
    }
  },
  async created() {
    this.executeRefreshTimeout();
    this.$store.dispatch('settings/initialize', this.settings);
  },
  async unmounted() {
    this.$store.dispatch('settings/reset');
    clearTimeout(this.timeoutRef);
  },
  async mounted() {
    this.assignee = this.me;
    await this.$store.dispatch('tasks/connect');
    await this.load();
  },
  methods: {
    async onSortChange(sort) {
      this.sort = sort;
      await this.load();
    },

    async onAssigneeChange(value) {
      this.assignee = value;
      await this.load();
    },

    async onFilterChanged(value) {
      const cleanedChange = value ? value.replaceAll(/[\/,]/gi, '').trim() : '';
      if (this.filter === cleanedChange) {
        return;
      }

      this.filter = cleanedChange;

      await this.load();
    },

    async load() {
      if (!this.isReady) {
        return;
      }

      try {
        this.isReady = false;
        clearTimeout(this.timeoutRef);
        if (this.expanded) {
          this.toggleOverview(this.expanded);
        }
        await this.$store.dispatch('milestonesOverview/getCollection', { sort: this.sort, assignee: this.assignee, filter: this.filter });
        this.executeRefreshTimeout();
      } finally {
        this.isReady = true;
      }
    },

    executeRefreshTimeout() {
      this.timeoutRef = setTimeout(async () => {
        if (!this.isRefreshing && this.isReady) {
          await this.refresh();
          this.executeRefreshTimeout();
        }
      }, 60 * 1000);
    },
    async toggleOverview(item) {
      this.currentItemHeight = null;
      await this.$store.dispatch('milestonesOverview/resetDetails');
      if (this.expanded === item.id) {
        this.expanded = null;
      } else {
        this.expanded = null;
        await waiter();
        this.expanded = item.id;
        await this.$store.dispatch('milestonesOverview/expand', this.expanded);
        const element = this.$refs.listRootRef?.querySelector('.detailes-wrapper');

        if (!element) {
          return;
        }

        const rects = element.getBoundingClientRect();
        this.currentItemHeight = rects.height;
        await this.$store.dispatch('milestonesOverview/getDetails', { id: item.id, assignee: this.assignee, filter: this.filter });
        this.$nextTick(() => {
          const child = element.children[0];
          const clone = child.cloneNode(true);
          clone.style.position = 'absolute';

          element.appendChild(clone);

          const { height } = clone.getBoundingClientRect();
          clone.remove();
          this.currentItemHeight = height;
          const self = this;
          setTimeout(() => {
            self.currentItemHeight = null;
          }, 200);
        });
      }

      await this.$store.dispatch('milestonesOverview/expand', this.expanded);
    },
    async more() {
      if (this.running) {
        return;
      }

      const scrollTop = this.$refs.listRootRef.scrollTop;
      await this.$store.dispatch('milestonesOverview/getCollection', {
        assignee: this.assignee,
        skip: this.collection.length,
        sort: this.sort,
        filter: this.filter
      });

      this.$refs.listRootRef.scrollTop = scrollTop;
    },
    onEditTask(task) {
      this.selected = task;
    },
    taskModalClosed() {
      this.selected = null;
      this.taskCreateOptions = null;
    },
    async onCreateTask(invention, milestone = null) {
      this.taskCreateOptions = {
        inventionId: invention.id,
        task: {
          createNew: true,
          assignees: [],
          workflow: {
            ...milestone?.workflow,
            milestoneId: milestone?.id,
            milestoneTitle: milestone?.title || '',
            milestoneAssignees: milestone?.assignees || [],
            milestoneDueAt: milestone?.dueAt,
            milestoneClientDueDate: milestone?.clientDueDate,
            milestoneTemplateId: milestone?.workflow?.template?.id,
            stepId: null
          }
        },
        tasks: milestone ? milestone.tasks : []
      };
    },
    async onCreateTaskModalClosed(event) {
      if (event && event.status == 'created') {
        this.selected = null;
        this.taskCreateOptions = null;
        this.$trackEvent(`Task created using 'Inventions' report`);
      }
    },
    async onMilestoneCreated() {
      this.$trackEvent(`Milestone created from 'My Active Inventions'`);
      this.isMilestoneCreateModalVisible = false;
      this.milestoneCreateOptions = null;
    },

    onCreateMilestone(invention) {
      this.milestoneCreateOptions = {
        invention,
        milestone: { createNew: true }
      };

      this.isMilestoneCreateModalVisible = true;
    },

    async refresh() {
      if (!this.isReady) {
        return;
      }

      this.isRefreshing = true;
      try {
        await this.$store.dispatch('milestonesOverview/getCollection', {
          assignee: this.assignee,
          sort: this.sort,
          filter: this.filter,
          refresh: true,
          size: this.collection.length + 2
        });
      } finally {
        this.isRefreshing = false;
      }
    },
    async taskEdited() {
      this.selected = null;
    },
    editMilestone(invention, milestone) {
      this.milestoneCreateOptions = { invention, milestone };
      this.isMilestoneCreateModalVisible = true;
    },
    prepareValue(value) {
      const label = value.includes('@') ? `${value.split('@')[0]} or ${value}` : `${value} team`;
      return { label, value };
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--theme-on-background-accent);
}

.overview-wrapper {
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 0.5rem;
  position: relative;
  padding-top: 80px;

  .results-pane {
    height: 100%;
    padding-top: 15px;

    .status {
      display: flex;
      align-items: flex-end;
      gap: 5px;
      padding-bottom: 20px;
      padding-left: 15px;
    }

    .help {
      background: var(--theme-surface);
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto 1fr;
      justify-items: flex-start;
      align-self: flex-start;
      padding: 15px;

      .column {
        padding-right: 20px;
      }

      .row {
        height: 35px;
        letter-spacing: 0.03rem;
        color: var(--theme-on-surface);
        display: flex;
        align-items: center;

        &.flex-end {
          padding-top: 25px;
          justify-content: flex-end;
        }

        a {
          color: var(--theme-primary);
        }

        &.query::before {
          content: '•';
          display: inline-block;
          margin-right: 10px;
        }
      }

      .header {
        font-size: 1rem;
        font-style: italic;
      }

      .request {
        text-decoration: underline;
        font-family: monospace;
        color: var(--theme-primary);
        margin: 0 2px;
      }

      .example {
        font-size: 0.9rem;
        font-family: monospace;
        color: var(--theme-primary);
        margin: 0 2px;
      }
    }
  }
  .center {
    display: flex;
    align-items: flex-end;
    padding: 10px;

    label {
      font-size: 0.8rem;
      color: var(--theme-on-surface-accent);
      padding-left: 7px;
      letter-spacing: 0.02rem;
    }
  }

  .left {
    justify-self: flex-start;
  }

  .right {
    justify-self: flex-end;
  }

  .overview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 5px;
    padding-right: 10px;
    background-color: var(--theme-surface);
    z-index: 5;
    width: calc(100% - 70px);
    position: fixed;
    top: 58px;

    &.with-panel {
      width: calc(100% - 370px);
    }

    .filters {
      display: flex;
      align-items: center;
      gap: 10px;

      .filter {
        width: 350px;
      }

      .me-icon {
        cursor: pointer;

        &:hover {
          color: var(--theme-primary);
        }
      }

      .multiselect-wrapper {
        min-width: 300px;
      }
    }
  }

  .column-headers {
    display: grid;
    grid-template-columns: 30px minmax(200px, 0.2fr) 0.7fr 0.7fr 1fr 80px 80px;
    justify-items: flex-start;
    align-items: center;
    padding: 8px 0;
    padding-right: 2px;
    z-index: 4;
    border-bottom: 1px solid var(--theme-surface);
    background-color: var(--theme-background);
    width: calc(100% - 70px);
    position: fixed;
    top: 101px;

    &.with-panel {
      width: calc(100% - 370px);
    }

    .task-mode {
      display: flex;
      align-items: center;

      .splitter {
        padding: 0 5px;
      }

      .switch {
        padding: 3px 7px;
        opacity: 0.5;
        user-select: none;
        &:hover {
          cursor: pointer;
        }
        &.selected {
          opacity: 1;
          background: var(--theme-primary);
          color: var(--theme-surface);
          border-radius: 2px;
        }
      }
    }

    label {
      font-size: 0.9rem;
      font-weight: 700;
      color: var(--theme-on-background-accent);
    }
  }

  .overview {
    height: 100%;
    .list {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 95%;

      &:not(:last-child) {
        margin-right: 6px;
      }

      &:last-child {
        height: 100%;
        box-sizing: border-box;
      }

      &.overview-list {
        display: grid;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        align-content: flex-start;

        .detailes-wrapper {
          display: flex;
          justify-content: center;
          transition: height 0.2s linear;

          .details {
            width: 70%;
          }
        }
      }
      .details-loading {
        place-self: center;
        padding-top: 0.5rem;
        height: 2.3rem;
        overflow: hidden;
      }

      .detailes-loading {
        display: flex;
        justify-content: center;
        height: 5rem;
        align-items: center;
      }
    }
  }
}
.flip-list-move {
  transition: transform 0.2s linear;
  z-index: 100;
}

.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 0.7s linear;
}
.flip-list-enter-from,
.flip-list-leave-to {
  opacity: 0.4;
  transform: translateX(50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s linear;
  transition-delay: 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.detailes-fade {
  opacity: 1;
}
</style>
