<template>
  <ul class="username-list">
    <li v-for="item of list" :key="item" :class="highlightClass(item)" class="username-list-item">{{ item }}</li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';

function asUsernameCollection(collection) {
  if (!collection || !collection.length) {
    return '';
  }

  return collection.filter(a => a?.length).map(s => '@' + s.split('@')[0]);
}

export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    highlightOptions: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      showColor: s => s.milestonesOverview.settings.colorCoding.value.toLowerCase()
    }),
    list() {
      return Array.isArray(this.value) ? asUsernameCollection(this.value) : [];
    }
  },
  methods: {
    highlightClass(assignee) {
      if (!this.highlightOptions || this.showColor === 'off') {
        return null;
      }
      const prepairedName = assignee
        .replace('@', '')
        .trim()
        .toLowerCase();
      if (this.highlightOptions.assignees.find(assignee => assignee.includes(prepairedName))) {
        return ['highlighted', this.highlightOptions.class];
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.username-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  .username-list-item {
    font-weight: 500;
    color: var(--theme-primary);
    padding: 1px 4px;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-bottom: 2px;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    letter-spacing: 0.02rem;

    &.highlighted {
      &.green {
        background-color: rgba(0, 128, 0, 0.4);
        color: var(--popper-theme-text-color);
      }

      &.yellow {
        background-color: rgba(255, 255, 0, 0.4);
        color: var(--theme-on-surface);
      }

      &.purple {
        background-color: rgba(128, 0, 128, 0.4);
        color: var(--theme-on-surface);
      }
      &.orange {
        background-color: rgba(255, 165, 0, 0.4);
        color: var(--popper-theme-text-color);
      }

      &.red {
        background-color: rgba(255, 0, 0, 0.2);
        color: var(--theme-on-surface);
      }
    }
  }
}
</style>
